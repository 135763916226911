import React from "react";

import BannerImageForm from "../../../shared2/BannerImageForm";

import AuthContext from "../../../../AuthContext";
import mtzApis from "../../../../services";
const { companyService } = mtzApis;

export default function CGEventBannerManager({
  onUpload,
  cgEventId,
  profileName,
  width,
}) {
  let [cgEvent, setCGEvent] = React.useState(null);
  let [companyGroupMember, setCompanyGroupMember] = React.useState(null);
  let [w, setW] = React.useState(width);
  const bannerRef = React.useRef();

  const me = React.useContext(AuthContext);

  React.useEffect(() => {
    let mounted = true;

    (async () => {
      let cgEvent;
      if (cgEventId) {
        const cgEvents = await companyService.getCGEvents(
          `cgEventIds=${cgEventId}`
        );
        cgEvent =
          cgEvents && cgEvents.length > 0 ? cgEvents[0] : null;
      }
      if (profileName) {
        const cgEvents = await companyService.getCGEvents(`profileName=${profileName}`);
        cgEvent =
          cgEvents && cgEvents.length > 0 ? cgEvents[0] : null;
      }

      if (!cgEvent) return;
      setCGEvent(cgEvent);

      let companyGroupMembers = await companyService.getCompanyGroupMembers(
        new URLSearchParams(`companyGroupIds=${cgEvent.companyGroupId}&userIds=${me.userId}`)
      );
      if (companyGroupMembers && companyGroupMembers.length > 0) {
        if (mounted) setCompanyGroupMember(companyGroupMembers[0]);
      }

      if (bannerRef && bannerRef.current) {
        if (!width) {
          if (mounted) setW(bannerRef.current.offsetWidth);
        } else
          if (mounted) setW(width);
      }
    })();

    return () => { mounted = false; };
  }, [cgEventId, profileName]);

  const uploadBanner = async (fileInfo) => {
    mtzApis.startSpinningIcon();
    const data = new FormData();
    data.append("updateParams", JSON.stringify({}));
    data.append("_uploadBanner", new File([fileInfo.blob], "bannerFile." + fileInfo.ext));
    cgEvent = await companyService.uploadCGEventFile(
      cgEvent.id,
      data
    );
    mtzApis.stopSpinningIcon();
    if (cgEvent) {
      setCGEvent(cgEvent);
      if (onUpload) onUpload(cgEvent.bannerUrl);
    }
  };

  return (
    <React.Fragment>
      {cgEvent && (
        <div id="mtz-cg-event-banner" className="w-100" ref={bannerRef}>
          {cgEvent && (
            <BannerImageForm
              height={w / 4 || 0}
              width={w || 0}
              editable={
                cgEvent &&
                companyGroupMember &&
                companyGroupMember.role === 'ADMIN'
              }
              imageUrl={
                cgEvent.bannerUrl
                  ? companyService.getBackendHost() + cgEvent.bannerUrl
                  : "/assets/images/empty.png"
              }
              onUpload={uploadBanner}
            />
          )}
        </div>
      )}
    </React.Fragment>
  );
}
