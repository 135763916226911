import React from 'react';

import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import AuthContext from '../../AuthContext';
import InstantChatManager from '../../components/chat-service/messages/InstantChatManager';
import CGEventDetail from '../../components/network-service/cgEvents/CGEventDetail';
import CGEventDetailNavbar from '../../components/network-service/cgEvents/CGEventDetailNavbar';
import mtzApis from '../../services';
import Header from '../ParentLayout/Header';
import NonLoginHeader from '../ParentLayout/NonLoginHeader';

const { commonUtil } = mtzApis;

function EventLayout(props) {
    const me = React.useContext(AuthContext);
    let [allowAccess, setAllowAccess] = React.useState(false);


    const checkLogin = async () => {
        if (window.location.pathname.startsWith("/me") ||
            window.location.pathname.startsWith("/timeline") ||
            window.location.pathname.startsWith("/mtz-admin")) {
            const tokens = commonUtil.getMTZLoginTokens();
            if (!tokens || tokens.length === 0) {
                this.state.allowAccess = false;
                return;
            }
        }

        setAllowAccess(true);
    }

    React.useEffect(() => {
        checkLogin();
    }, [window.location.href]);

    return (
        <>
            {
                allowAccess === true ?
                    <div className="vh-100 w-100 d-flex flex-column mtz-gap-16">
                        {!!me.userId && <InstantChatManager />}



                        <div className='w-100 sticky-top'>
                            {
                                !!me.userId ?
                                    <Header asSimple={true} /> :
                                    <NonLoginHeader asSimple={true} />
                            }
                        </div>

                        <div className='h-100 d-flex flex-grow-1 flex-column'>
                            <div className='d-flex justify-content-center'>
                                <div className='w-100 d-flex flex-column mtz-gap-16' style={{ maxWidth: '1306px' }}>
                                    <CGEventDetail profileName={props.match.params.eventProfileName} />

                                    <CGEventDetailNavbar />
                                </div>
                            </div>

                            <props.component />
                        </div>
                    </div>
                    :
                    <div className="p-2">Login required. Please <a href="/login">Login</a> to access.</div>
            }
        </>
    );
}

export default withRouter(EventLayout);
